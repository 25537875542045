.ant-drawer-close {
  display: none !important;
}

.ant-drawer-title {
  color: #fff !important;
  font-size: 24px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: normal !important;
}

.ant-drawer-header {
  background-color: #fff !important;
  color: #000 !important;
}
.drawers_close_btn {
  color: #000;
  font-size: 30px;
  background-color: transparent;
  border: none;
  outline: none;
}

.ant-drawer-body {
  padding-top: 0px !important;
  background-image: url("../../../assets//menu//menu-bg.png");
  background-repeat: no-repeat;
  background-position: bottom right;
  /* background-size: 100% 300px; */
}

@media only screen and (max-width: 520px) {
  .ant-drawer-header {
    background-color: #000 !important;
    color: #fff !important;
    padding: 16px 10px !important;
  }

  .ant-drawer-body{
    padding: 0 10px !important;
  }

  .drawers_close_btn{
    color: #fff;
  }
}
@media only screen and (max-width: 359px) {
  .drawers_close_btn {
    font-size: 25px;
  }
}
@media only screen and (max-width: 302px) {
  .drawers_close_btn {
    font-size: 23px;
  }
}

/* @media only screen and (max-width: 820px) and (max-height: 1180px) {
  .ant-drawer-body {
    padding-top: 0px !important;
    background-image: url("../../../assets//menu//menu-bg.png");
    background-repeat: no-repeat;
    background-position: bottom right;
    background-size: 100%;
  }
} */
