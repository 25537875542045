.Wrapper {
  background-color: #243238;
}

.Container {
  max-width: 1250px;
  width: 100%;
  margin: 0 auto;
  /* padding: 0 10px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.row {
  margin: 0 !important;
}

.navigation ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px;
}
.navigation ul li p {
  color: #fff;
  /* Text/Heading 2 */
  /* font-family: Okta Neue; */
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}
.Navlink {
  color: #eee;
  /* font-family: Okta Neue; */
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 20.8px */
  text-decoration: none;
}
.navigation ul li {
  margin-bottom: 14px;
}
.row {
  margin-bottom: 80px;
}
.Col_right {
  width: 100%;
  border-radius: 0px 0px 10px 10px;
  background: #1e2b31;
  box-shadow: 0px 0px 20px 0px rgba(255, 255, 255, 0.05);
}
.box {
  padding: 60px;
}
.box img {
  width: 225px;
  height: 42px;
  display: flex;
}
.box p {
  color: #aaa;
  /* font-family: Okta Neue; */
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  text-align: left;
}
.box hr {
  width: 100%;
  color: #696969;
}
.network {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.network a {
  color: #aaa;
  /* font-family: Okta Neue; */
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  margin-bottom: 30px;
  text-decoration: none;
  display: flex;
  align-items: center;
  margin-right: 15px;
}
.network a:nth-child(1) {
  margin-top: 20px;
}
.network a i {
  color: #df2027;
  font-size: 24px;
  position: relative;
  right: 5px;
}
.network_contact {
  margin: 0;
  padding: 0;
  margin-top: 10px;
}
.network_contact p {
  color: #fff;
  /* font-family: Okta Neue; */
  font-size: 20px;
  font-style: normal;
  display: flex;
  font-weight: 400;
  line-height: 150%; /* 36px */
}
.network_contact span {
  color: #eee;
  /* font-family: Okta Neue; */
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  display: flex;
  align-items: center;
  margin-left: 5px;
  line-height: 130%; /* 20.8px */
}
.network_contact span i {
  position: relative;
  right: 5px;
  color: #df2027;
  font-size: 20px;
}
.network_contact h5 {
  color: #aaa;
  /* font-family: Okta Neue; */
  font-size: 16px;
  text-align: left;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 19.2px */
}
.network_contact > button {
  background: #df2027;
  border-radius: 5px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 160%;
  cursor: pointer;
  color: #ffffff;
  text-decoration: none;
  border: none;
  outline: none;
  padding: 15px 20px;
  width: 100%;
  display: flex;
  margin-left: auto;
  justify-content: center;
  transition: 0.3s;
}

.network_contact > button > a{
  color: #fff;
  text-decoration: none;
}

.network_contact > button:hover,
.network_contact > button:focus {
  transform: scale(0.95);
  cursor: pointer;
}
.social_media {
  margin-top: 50px;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 0;
}
.social_media > li {
  background-color: #bebebe;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  color: #fff;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}

.social_media > li > a {
  color: black;
  font-size: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}

@media only screen and (max-width: 1100px) {
}
@media only screen and (max-width: 882px) {
  .Container {
    width: 90%;
  }
  .row {
    margin: 0;
  }
  .social_media {
    justify-content: space-between;
  }
  .network {
    display: none;
  }
  .Col_right {
    width: 100%;
    border-radius: 0px;
    background: transparent;
    box-shadow: transparent;
  }
  .box {
    padding: 0px;
    padding-top: 20px;
  }
  .navigation ul {
    padding: 0px;
  }
}

@media only screen and (max-width: 520px) {
  .Wrapper {
    background: #000;
  }
  .Col_right,
  .Col_left {
    box-shadow: none !important;
    padding: 0 !important;
  }
}
