.header_btm_col_list {
  list-style: none;
  padding-left: 0;
}
.header_btm_col_list > li > a {
  text-decoration: none;
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%;
}

.header_btm_col_list > li {
  padding: 5px 0;
  width: 100%;
}

.menu_hr {
  width: 100%;
  height: 1px;
  background: #efefef;
}

.drawers_title {
  display: none;
}

.social_media {
  margin-top: 50px;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 0;
}
.social_media > li {
  background-color: #e2363c;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  color: #fff;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}

.social_media > li > a {
  color: #fff;
  font-size: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 520px) {
  .drawers_title {
    display: block;
  }
  .drawers_title > h4 {
    margin: 0 !important;
  }
  .drawers_img {
    display: none;
  }

  .header_btm_col_list > li {
    padding: 10px 0;
    width: 100%;
  }
}

@media only screen and (max-width: 425px) {
  .social_media {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

@media only screen and (max-width: 359px) {
  .drawers_title > h4 {
    font-size: 20px;
  }

  .social_media {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
@media only screen and (max-width: 302px) {
  .drawers_title > h4 {
    font-size: 18px;
  }
}

/* @media only screen and (max-width: 820px) and (max-height: 1180px) {
  .header_btm_col_list > li {
    padding: 14px 0;
  }
} */
