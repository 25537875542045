.header {
  background: #fff;
  position: fixed;
  /* width: 100%; */
  top: 0;
  right: 0;
  left: 0;
  z-index: 100;
  box-shadow: 20px 20px 40px 0px rgba(0, 0, 0, 0.05);
}

.hr {
  width: 100%;
  height: 1px;
  background: #e2e2e2;
}

@media only screen and (max-width: 1300px) {
  .header {
    padding: 0 20px;
  }
}
@media only screen and (max-width: 1100px) {
  .header {
    padding: 0;
  }
}
@media only screen and (max-width: 991px) {
  .hr {
    display: none;
  }
  .header {
    padding: 10px 0;
  }
}

@media only screen and (max-width: 520px) {
  .header {
    background: #000;
    padding: 16.5px 0;
  }
}
